var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tags"
  }, _vm._l(_vm.items, function (item, index) {
    return _c('div', {
      key: index,
      class: {
        'tag-item': true,
        selected: _vm.selected(item.id)
      },
      on: {
        "click": function ($event) {
          return _vm.selectTag(item.id);
        }
      }
    }, [_vm._v(" " + _vm._s(item.label) + " "), item.count ? _c('div', {
      staticClass: "count"
    }, [_vm._v(" " + _vm._s(item.count) + " ")]) : _vm._e()]);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }