var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "product-binding-modal",
      "size": "xl",
      "centered": "",
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function () {
        var _vm$product, _ref, _vm$product$name, _vm$product2, _vm$product3;

        return [_c('h5', [_vm._v(" Привязка товара " + _vm._s((_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : _vm$product.articul) + " | " + _vm._s((_ref = (_vm$product$name = (_vm$product2 = _vm.product) === null || _vm$product2 === void 0 ? void 0 : _vm$product2.name) !== null && _vm$product$name !== void 0 ? _vm$product$name : (_vm$product3 = _vm.product) === null || _vm$product3 === void 0 ? void 0 : _vm$product3.productItem_name) !== null && _ref !== void 0 ? _ref : '-') + " ")]), _c('b-button', {
          staticClass: "close",
          on: {
            "click": _vm.hideModal
          }
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/modal_close.svg",
            "alt": ""
          }
        })])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "ext-caption mb-3"
  }, [_c('img', {
    staticClass: "mr-2",
    attrs: {
      "src": "/img/icons/info_blue.svg"
    }
  }), _c('span', {
    staticClass: "text-dark"
  }, [_vm._v("Чтобы привязать товар необходимо нажать на товарную карточку или добавить новый ")])]), _c('div', {
    staticClass: "mb-3",
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "overflow": "hidden"
    }
  }, [_c('binding-products-menu', {
    staticStyle: {
      "min-height": "500px",
      "min-width": "25%",
      "height": "70vh"
    }
  }), _c('binding-product-table', {
    staticStyle: {
      "display": "flex",
      "margin-bottom": "0 !important",
      "min-height": "600px",
      "flex": "1"
    },
    attrs: {
      "product": _vm.product,
      "document_head": _vm.document_head,
      "type": _vm.type
    },
    on: {
      "bind": _vm.bind
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }