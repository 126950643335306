<template>
  <div class="diagnosis-list">
    <!-- <h1 class="mb-3">Рекомендации</h1> -->
    <product-binding-modal :product="selectedBindingProduct" />
    <tags
      :items="tags"
      :selected="selectedTag"
      @select-tag="selectTag"
    />
    <router-view v-if="tags.length" />
    <div
      v-if="loading"
      class="diag-spinner"
    >
      <b-spinner
        variant="primary"
        label="Загрузка..."
      />
    </div>
    <div
      v-if="!tags.length && !loading"
      class="d-flex flex-column justify-content-center h-100"
    >
      <div
        style="
          font-style: normal;
          font-weight: 500;
          font-size: 22px;
          line-height: 26px;
          text-align: center;
          color: #000000;
        "
      >
        С вашим учетом всё в порядке!
      </div>
      <div
        class="mt-3"
        style="
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          color: #888888;
        "
      >
        Рекомендации появятся как только произойдут ошибки учёта или накопится достаточно данных
      </div>
    </div>
    <!-- <crp-tech-tab v-if="selectedTag === 'crptech'" /> -->
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Tags from '@/views/diagnostics/components/Tags.vue'
  import { ProductModel } from '@/models/product.model'
  import ProductBindingModal from '@/views/operational-processes/components/incoming/BindingModal.vue'
  import CrpTechTab from '@/views/diagnostics/components/CrpTechTab.vue'

  export default {
    name: 'DiagnosticsList',
    components: {
      CrpTechTab,
      Tags,
      ProductBindingModal
    },
    apollo: {
      NecessaryRecomendations: {
        query: require('../gql/NecessaryRecomendations.graphql'),
        variables() {
          return {
            input: {
              branch: this.currentBranch.id
            }
          }
        },
        result({ data }) {
          this.loading = false
          this.tags = data.NecessaryRecomendations?.map((el) => {
            return {
              label: this.mapping[el.name].label,
              count: el.total,
              id: this.mapping[el.name].id
            }
          }).sort((a, b) => (a.name > b.name ? 1 : -1))
          if (this.$route.name === 'diagnostics.list' && this.tags[0]) this.selectTag(this.tags[0].id)
        }
      }
    },
    beforeRouteUpdate(to, from, next) {
      if (from.name.includes(to.name)) return
      next()
    },
    data() {
      return {
        loading: true,
        pagination: { skip: 0, take: 20 },
        negativeItems: [],
        firstLoading: true,
        negativeTotal: 0,
        selected: [],
        selectedTag: '',
        selectedBindingProduct: new ProductModel(),
        selected_all: false,
        total: 0,
        tags: [],
        mapping: {
          ProductSearchRemErrored: { label: 'ЕГАИС', id: 'egais' },
          crptech: { label: 'Честный знак', id: 'crptech' },
          CurrentRemainders: { label: 'Отрицательные остатки', id: 'negative_remains' },
          PartAnal: { label: 'Сроки годности', id: 'expiring' }
        }
      }
    },
    methods: {
      getRemainders(remainders) {
        return remainders?.reduce((sum, el) => sum + el?.value, 0)
      },
      selectTag(id) {
        this.selectedTag = id
        this.$router.push({ name: 'diagnostics.list.' + id })
      }
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      })
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep .children {
    div {
      display: flex;
      float: left;
    }
  }
  ::v-deep .cursor:not(:nth-child(4)) {
    .td-div {
      display: flex !important;
      float: left !important;
      justify-content: flex-start !important;
      width: 100% !important;
    }
  }
  .diagnosis-list {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    // overflow: hidden;
  }
  .diag-spinner {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
</style>
