var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "diagnosis-list"
  }, [_c('product-binding-modal', {
    attrs: {
      "product": _vm.selectedBindingProduct
    }
  }), _c('tags', {
    attrs: {
      "items": _vm.tags,
      "selected": _vm.selectedTag
    },
    on: {
      "select-tag": _vm.selectTag
    }
  }), _vm.tags.length ? _c('router-view') : _vm._e(), _vm.loading ? _c('div', {
    staticClass: "diag-spinner"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "primary",
      "label": "Загрузка..."
    }
  })], 1) : _vm._e(), !_vm.tags.length && !_vm.loading ? _c('div', {
    staticClass: "d-flex flex-column justify-content-center h-100"
  }, [_c('div', {
    staticStyle: {
      "font-style": "normal",
      "font-weight": "500",
      "font-size": "22px",
      "line-height": "26px",
      "text-align": "center",
      "color": "#000000"
    }
  }, [_vm._v(" С вашим учетом всё в порядке! ")]), _c('div', {
    staticClass: "mt-3",
    staticStyle: {
      "font-style": "normal",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "19px",
      "text-align": "center",
      "color": "#888888"
    }
  }, [_vm._v(" Рекомендации появятся как только произойдут ошибки учёта или накопится достаточно данных ")])]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }