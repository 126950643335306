<template>
  <div class="tags">
    <div
      v-for="(item, index) in items"
      :key="index"
      :class="{ 'tag-item': true, selected: selected(item.id) }"
      @click="selectTag(item.id)"
    >
      {{ item.label }}
      <div
        v-if="item.count"
        class="count"
      >
        {{ item.count }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Tags',
    props: {
      items: {
        type: Array,
        default: () => []
      }
    },
    methods: {
      selectTag(id) {
        if (!this.selected(id)) this.$emit('select-tag', id)
      },
      selected(id) {
        return this.$route.name.includes(id)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }

  .tag-item {
    background: #ffffff;
    color: #313131;
    border: 1px solid #e1e1e1;
    border-radius: 100px;
    padding: 8px 10px 8px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;

    .count {
      padding: 0 6px;
      height: 18px;
      margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 144px;
      background: #00a3ff;
      color: white;
    }
  }

  .tag-item.selected {
    background: #00a3ff;
    border: 1px solid #00a3ff;
    color: #ffffff;
    font-weight: 400;
    font-size: 14px;
    .count {
      background: white;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #00a3ff;
    }
  }
</style>
