<template>
  <b-modal
    id="product-binding-modal"
    size="xl"
    centered
    hide-footer
  >
    <template #modal-header>
      <h5>
        Привязка товара {{ product?.articul }} |
        {{ product?.name ?? product?.productItem_name ?? '-' }}
      </h5>
      <b-button
        class="close"
        @click="hideModal"
      >
        <img
          src="/img/icons/modal_close.svg"
          alt=""
        />
      </b-button>
    </template>
    <div class="ext-caption mb-3">
      <img
        src="/img/icons/info_blue.svg"
        class="mr-2"
      />
      <span class="text-dark">Чтобы привязать товар необходимо нажать на товарную карточку или добавить новый </span>
    </div>
    <div
      class="mb-3"
      style="
        display: flex;
        flex-direction: row;

        overflow: hidden;
      "
    >
      <binding-products-menu style="min-height: 500px; min-width: 25%; height: 70vh" />
      <binding-product-table
        style="display: flex; margin-bottom: 0 !important; min-height: 600px; flex: 1"
        :product="product"
        :document_head="document_head"
        :type="type"
        @bind="bind"
      />
    </div>
  </b-modal>
</template>

<script>
  import BindingProductTable from '@/views/operational-processes/components/incoming/binding/BindingProductTable'
  import BindingProductsMenu from '@/views/operational-processes/components/incoming/binding/BindingProductsMenu'
  import { ProductModel } from '@/models/product.model'

  export default {
    name: 'ProductBindingModal',
    components: { BindingProductsMenu, BindingProductTable },
    props: {
      product: {
        type: ProductModel,
        default: () => new ProductModel()
      },
      document_head: {
        type: String,
        default: () => ''
      },
      type: {
        type: String,
        default: () => ''
      }
    },
    methods: {
      hideModal() {
        this.$bvModal.hide('product-binding-modal')
      },
      bind() {
        this.$emit('bind', this.product)
      }
    }
  }
</script>

<style scoped>
  .ext-caption {
    display: flex;
    padding: 12px 16px;
    align-items: center;
    background: #e6f0ff;
    border: 1px solid #b9d6ff;
    border-radius: 4px;
  }
</style>
